import { createSlice } from '@reduxjs/toolkit';
import {
    fetchDeployedCount,
    fetchDeployedStakersInfo
} from './fetchStakingLauncherGlobalInfo';


interface StakingContractState {
    deployedStakers: { [index: number]: {isUserToken: boolean, symbol: string, deployer: string, stakeTokenAddress: string, rewardTokenAddress: string, index: number} };
    size: number;
    stakerReloadCount: 0

}


const initialState: StakingContractState = {
    deployedStakers: [],
    size: 0,
    stakerReloadCount: 0
};

export const stakingContractSlice = createSlice({
    name: 'staking',
    initialState,
    reducers: {
        reset: (state) => {
            state.deployedStakers = []
            state.stakerReloadCount = 0
            state.size = 0;
        },
        setDeployedStakingSize: (state, action) => {
            state.size = action.payload.data;
        },
        setFarmGlobalData: (state, action) => {
              for( let i = 0; i < action.payload.data.tokens.length; i+=1 ){
                if( action.payload.data.tokens[i].index !== -1 )
                  state.deployedStakers[action.payload.data.tokens[i].index] = action.payload.data.tokens[i];
              }},
              setStakerReloadCount: (state, action) => {
                state.stakerReloadCount = action.payload.data;
            },
    },
});

export const { setDeployedStakingSize, setFarmGlobalData, setStakerReloadCount, reset } = stakingContractSlice.actions;

export const resetDataStakers = () => async (dispatch: any): Promise<void> => { 
    dispatch( reset( ))
}
export const setStakersReloadCountAsync = (count: number) => async (dispatch: any): Promise<void> => { 
    dispatch( setStakerReloadCount( {
        data: count
    }))
  }

export const fetchDeployerStContractInfoAsync = (chainId: number, index: number, address: string) =>
    async (dispatch: any): Promise<void> => {
        try {
          

        } catch (error: any) {
            console.error('Error in fetchDeployerStContractInfoAsync:', error);
        }
    };

export const fetchDeployedStakingSizeAsync =
    (chain_id: number) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchDeployedCount(chain_id);

            dispatch(
                setDeployedStakingSize({
                    data,
                })
            );
        };

export const fetchDeployedStakingDataAsync =
    (chainId: number, account: string, indexes: number[], ftmPrice: number) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchDeployedStakersInfo(chainId, account, indexes, ftmPrice)


            dispatch(
                setFarmGlobalData({
                    data,
                })
            );
        };

export default stakingContractSlice.reducer;
