import { FC, useState } from 'react';
import { Box, styled } from '@mui/material';

import ListedLocker from 'components/ListedLocker/ListedLocker';
import DeployLocker from "../components/DeployLocker/DeployLocker";

// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    background: 'transparent',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
    },
}));


interface Props {
}

const LockerLauncherPage: FC<Props> = ({ }) => {

    const [isLockerMiniOpen, setLockerMiniOpen] = useState<boolean>(false)

    const handleOpenLockerMini = (state: boolean) =>
    {
        setLockerMiniOpen(state);
    }

    return (
        <>
            <OverviewSection mt={5}>
                { !isLockerMiniOpen &&
                    <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{}}>
                        <DeployLocker/>
                    </Box>
                }

                <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{width: '100%'}}>
                    <ListedLocker handleOpenLockerMini={handleOpenLockerMini}/>
                </Box>
            </OverviewSection>
        </>
    );
};

export default LockerLauncherPage;
