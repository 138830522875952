import { FC, useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import LockerSection from './Components/LockerSection';
import InfoSection from './Components/InfoSection';
import LockerAssetsSection from './Components/LockerAssetsSection';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { ImageType } from 'react-images-uploading';
import { useWeb3React } from '@web3-react/core';
import {useDeployerLocker, useDeployLockerInfo, useDeployTokenInfo} from "../../hooks/useDeployLocker";
import { ethers} from 'ethers';
import NotificationModal from 'components/Modal/NotificationModal/NotificationModal';
import FancyDropDownBox from "../common/DropDownBox/FancyDropDownBox";
import {fetchDeployedLockerSizeAsync, setLockersReloadCountAsync} from "../../state/lockerLauncher/lockerSlice";
import useContract from 'hooks/useContract';
import ERC20 from "../../config/abi/Erc20.json";
import {AbiItem} from "web3-utils";
import CreateLockerNotificationModal from "../Modal/NotificationModal/CreateLockerNotificationModal";

interface DeployLockerProps {
    
}

const DeployLocker: FC<DeployLockerProps> = ({  }) => {
    const dispatch = useAppDispatch();

    const [isCheckErrors, setIsCheckErrors] = useState<boolean>(false);
    const [lockerName, setLockerName] = useState<string>('');
    const [liquidityPairAddress, setLiquidityPairAddress] = useState<string>('');
    const [lpAmount, setLpAmount] = useState<string>('');
    const [lockDuration, setLockDuration] = useState<string>('');

    const { account } = useWeb3React();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [lockersReloadCount, setLockersReloadCount] = useState<number>(0);
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const { selectedChainId } = useAppSelector((state) => state.chain);
    const tokenContract = useContract(ERC20 as unknown as AbiItem, liquidityPairAddress);
    // const contract = useContract(getDeployerStABI(Number(selectedChainId)) as unknown as AbiItem, getStakingLauncherInfo(Number(selectedChainId))[0]?.contractAddress);
    const {onDeploy, onApprove} = useDeployerLocker(Number(selectedChainId), liquidityPairAddress);
    const [isDeployed, setIsDeployed] = useState<boolean>(false);
    const [tg, setTg] = useState<string>('');
    const [x, setX] = useState<string>('');
    const [web, setWeb] = useState<string>('');
    const [customLink, setCustomLink] = useState<string>('');
    const [images, setImages] = useState<ImageType[]>([]);
    const [counter, setCounter] = useState<number>(0);
    const deployInfo = useDeployLockerInfo(Number(selectedChainId), liquidityPairAddress, counter)
    const rewardTokenInfo = useDeployTokenInfo(Number(selectedChainId), liquidityPairAddress, counter)
    const stakingTokenInfo = useDeployTokenInfo(Number(selectedChainId), liquidityPairAddress, counter)

    const [accountRewardBalance, setAccountRewardBalance] = useState<string>('');

    const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>(true);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const rollUpDropDown = () =>
    {
        setIsExpanded(false);
    }

    useEffect(() => {
        if (
            !liquidityPairAddress ||
            !lpAmount ||
            !lockDuration
        ) {
            setIsFieldEmpty(true);
        } else {
            setIsFieldEmpty(false);
        }
    }, [liquidityPairAddress, lpAmount, lockDuration, isExpanded]);

    const clearFields = () => {
        setLockerName('');
        setLiquidityPairAddress('');
        setLpAmount('');
        setLockDuration('');
        setTg('');
        setX('');
        setWeb('');
        setCustomLink('');
    };

    useEffect(() => {
        clearFields();
    }, [isExpanded]);

    const onConfirm = async () => {
        setLoading(true);
        if (isApproved) {

            try {
                const response = await onDeploy(liquidityPairAddress , lpAmount, lockDuration );
                console.log('responseDeployLock', response)
                if(response)
                {
                    setIsDeployed(true)
                    setModalOpen(true)
                    rollUpDropDown()
                    dispatch(setLockersReloadCountAsync(lockersReloadCount + 1));
                    dispatch(fetchDeployedLockerSizeAsync(Number(selectedChainId)))
                }
                
            } catch (error) {
                console.log('DeployLocker err: ', error);
            }
        } else {
            try {
                await onApprove(lpAmount);
                setCounter(counter + 1)
            } catch (error) {
                console.log('Approve err: ', error);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        // const isApproved = account && deployInfo ? deployInfo.allowance.gte(rewardTokenAmount) : false
        if( account && deployInfo && lpAmount ){
            if( deployInfo.allowance.gte(ethers.utils.parseUnits(lpAmount, 18))){

                setIsApproved(true)
            } else {

                setIsApproved(false)
            }
        }
      }, [account, deployInfo, lpAmount]);

      useEffect(() => {
        // const isApproved = account && deployInfo ? deployInfo.allowance.gte(rewardTokenAmount) : false
        if( account && deployInfo && rewardTokenInfo ){

            try{
                const balance = ethers.utils.formatUnits(deployInfo.accountBalance, rewardTokenInfo.decimals)

                setAccountRewardBalance(Number(balance).toFixed(2))
            }
            catch(err){
                console.log(err)
            }
        }
      }, [account, deployInfo, rewardTokenInfo]);

    useEffect(() => {

        // if( liquidityPairAddress.length > 0 && isDeployed ){
        //     setModalOpen(true)
        //     rollUpDropDown()
        //     dispatch(setLockersReloadCountAsync(lockersReloadCount + 1));
        //     dispatch(fetchDeployedLockerSizeAsync())
        // }
      }, [isDeployed, liquidityPairAddress]);

    const  lockerSectionChanges =  (
                                    liquidityPairAddress: string,
                                    lpAmount: string,
                                    lockDuration: string) => {
                                        

        console.log(liquidityPairAddress)
        console.log(lpAmount)
        console.log(lockDuration)
        setLiquidityPairAddress(liquidityPairAddress);
        setLpAmount(lpAmount);
        setLockDuration(lockDuration);
    }

    return (
        <>
            {modalOpen && <CreateLockerNotificationModal open={modalOpen} handleClose={() => {setModalOpen(false);}}/>}

        <FancyDropDownBox globalTittle={'Create Locker'} containerSX={{padding: { md: '20px 75px' },}} isExpanded={isExpanded}
                              setIsExpanded={setIsExpanded}>

            <Box display='flex' flexDirection='column' mt={2}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Box display='flex' flexDirection='column'>
                            <LockerSection isCheckErrors={isCheckErrors} lockerSectionChanges={lockerSectionChanges}
                                           rewardTokenInfo={rewardTokenInfo}
                                           stakingTokenInfo={stakingTokenInfo}
                                           rewardTokenBalance={accountRewardBalance}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} mt={3} mb={4}>
                        <Box display='flex' flexDirection='column'>
                            <LockerAssetsSection
                                isCheckErrors={isCheckErrors}
                                onCreateClick={onConfirm}
                                isApproved={isApproved}
                                isLoading={isLoading}
                                isDeployed={isDeployed}
                                isFieldEmpty={isFieldEmpty}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <InfoSection/>

            </Box>
        </FancyDropDownBox>
        </>
    );
};

export default DeployLocker;
