import { useState } from 'react';

const useSearchComponent = <T extends object>(items: T[], searchField: keyof T) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSearch = async (query: string): Promise<T[]> => {
        setLoading(true);
        console.log('query', query);
        try {
            if (!query) {
                setSearchResults([]);
                setLoading(false);
                return [];
            }

            const lowerCaseQuery = query.toLowerCase();

            const results = items.filter(item => {
                const value = item[searchField];
                return value && String(value).toLowerCase().includes(lowerCaseQuery);
            });

            setSearchResults(results);
            console.log('results', results);
            return results;
        } finally {
            setLoading(false);
        }
    };


    return {
        searchQuery,
        setSearchQuery,
        searchResults,
        handleSearch,
        loading,
    };
};

export default useSearchComponent;
