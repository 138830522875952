import React, { FC,  useEffect, useState} from 'react';
import {Box, CircularProgress, Grid, IconButton, styled, Typography} from '@mui/material';
import {ContainedButton,} from 'components/common/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useCountDownUp from 'hooks/useCountDownUp';
import {InfoLabelBegin, InfoLabelEnd} from "../common/Typography/InfoLabel";
import {useWeb3React} from "@web3-react/core";
import {useAppSelector} from "../../state/hooks";
import { CHAIN_INFO } from 'config/constants/chains';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExtendedTimer from "../common/TimeComponent/ExtendedTimer";
import InfoSection from "./Components/InfoSection";
import {goToChainExplorer} from "../../utils/chain";
import {ContainedTextField} from "../common/Input/ContainedTextField";
import { useDeployerLocker } from 'hooks/useDeployLocker';
import CreateLockerNotificationModal from "../Modal/NotificationModal/CreateLockerNotificationModal";
import RelockNotificationModal from "../Modal/NotificationModal/RelockNotificationModal";

const Container = styled(Box)(({theme}) => ({
    position: 'relative',
    width: '100%',
    marginTop: '30px',
    background: "rgba(42,49,57, 0.4)",
    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    flexDirection: 'column',
    minHeight: '500px',
    minWidth: '300px',
    paddingBottom: '50px',
    [theme.breakpoints.up('xs')]: {
        padding: '0px 16px',
        minWidth: '280px',
        paddingBottom: '50px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '20px 32px',
    },
}));

const HeaderContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
       flexDirection: 'column',
        justifyContent: 'space-between',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));


const ArrowBackContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    position: 'absolute',
    border: '1px solid #5E6980',
    borderRadius: '15px',
    padding: '10px',
    background: '#2A3139',
    cursor: 'pointer',
    [theme.breakpoints.up('xs')]: {
        top: -50,
        left: 0,
    },
    [theme.breakpoints.up('md')]: {
        top: -50,
        left: -50,
    },
}));


const PoolName = styled(Typography)(({ theme }) => ({
    position: 'relative',
    border: '0px solid green',
    fontFamily: 'Prompt',
    fontWeight: '600',
    cursor: 'text',
    fontSize: '35px',
    lineHeight: '30px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '250px',

    [theme.breakpoints.up('xs')]: {
        fontSize: '24px',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {

    },
    [theme.breakpoints.up('md')]: {
        fontSize: '30px',
        whiteSpace: 'wrap',
    },
}));

const PoolSymbol = styled(Typography)(({theme}) => ({
    border: '0px solid red',
    fontFamily: 'Prompt',
    fontWeight: '600',
    fontSize: '24px',
    
    lineHeight: '24px',
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    margin: 0,
    padding: 0,
    display: 'inline',

    [theme.breakpoints.up('xs')]: {
        margin: 0,
        padding: 0,
        display: 'inline',
        fontSize: '24px',

        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
        fontSize: '24px',

    },
    [theme.breakpoints.up('md')]: {

        whiteSpace: 'break-spaces',
        fontSize: '24px',

    },
}));


// modal body
const ModalBody = styled(Box)(() => ({
    marginTop: '23px',
    marginBottom: '21px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    gap: '20px'
}));

const RelockSection = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '30px',
    borderRadius: '10px',
    gap: '16px',
    
}))

interface Props {
    index?: number,
    lockerInfo?: any,
    isSepparatePage: boolean,
    images: any,
    onClose?: () => void
    relockModalOpen?: () => void
    withdrawModalOpen?: () => void
}

const LockerModal: FC<Props> = ({index, isSepparatePage, lockerInfo, onClose, relockModalOpen, withdrawModalOpen}) => {
    const { account } = useWeb3React();
    const { selectedChainId } = useAppSelector((state) => state.chain);
    const [relockDaysCount, setRelockDaysCount] = useState<string>('');
    const [isRelockLoading, setRelockLoading] = useState<boolean>(false);
    const [isWithdrawLoading, setWithdrawLoading] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const {onDeploy, onWithdrawLocker, onRelockExtend} = useDeployerLocker(Number(selectedChainId), lockerInfo?.lpAddress);

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
    }
    const onChangeRelockDaysCount = (e: any) => {
        const newValue = e.target.value;
        if (!isNumeric(newValue)) return;
        console.log(newValue)
        setRelockDaysCount(newValue);
    };
        const truncate = (str: string, n: number) => {
        return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };
    
    const tableOpenNewIcon = (address:string) => {
        return (
            <IconButton color={'primary'} sx={{}} onClick={(e) => {
                e.stopPropagation();
                goToChainExplorer(Number(selectedChainId), address);
            }}>
                <OpenInNewIcon color={'primary'}/>
            </IconButton>);

    }

    const tableFields = [
        {name: `Loquidity Pair Address`, value: truncate(lockerInfo?.lpAddress, 4), icon: tableOpenNewIcon(lockerInfo?.lpAddress)},
        {name: `Locker Address`, value: truncate(lockerInfo?.lockerAddress, 4), icon: tableOpenNewIcon(lockerInfo?.lockerAddress)},
        {name: `${lockerInfo?.token0Name} Address`, value: truncate(lockerInfo?.token0Address, 4), icon: tableOpenNewIcon(lockerInfo?.token0Address)},
        {name: `Locker ID`, value: truncate(lockerInfo?.lockerIndex, 4)},
        {name: `${lockerInfo?.token1Name} Address`, value: truncate(lockerInfo?.token1Address, 4), icon: tableOpenNewIcon(lockerInfo?.token1Address)},
        {name: `% of Liquidity Locked`, value: lockerInfo?.percent.toFixed(4)},
    ]

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = Date.now();
            const difference = lockerInfo?.poolEndTime * 1000 - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / 1000 / 60) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setTimeLeft({
                    days,
                    hours,
                    minutes,
                    seconds,
                });
            } else {
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });
            }
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, [lockerInfo]);

    const {days, hours, minutes, seconds} = timeLeft;
    
    const onRelock = async () => {
        setRelockLoading(true);

            try {
                console.log(lockerInfo)
                const response = await onRelockExtend(lockerInfo.token_address, lockerInfo.lock_id, lockerInfo.token_id, relockDaysCount)
                console.log('onRelockResult: ', response)
                if (response)
                {
                    if (relockModalOpen) {
                        relockModalOpen()
                    }
                }
            } catch (error) {
                console.log('DeployStakingPool err: ', error);
            }
        setRelockLoading(false);
    };

    const onWithdraw = async () => {
        setWithdrawLoading(true);

            try {
                console.log(lockerInfo)
                const response = await onWithdrawLocker(lockerInfo.token_address, lockerInfo.lock_id, lockerInfo.token_id)
                console.log('withdrawResult: ', response)
                if (response)
                {
                    if (withdrawModalOpen) {
                        withdrawModalOpen()
                    }
                }
            } catch (error) {
                console.log('DeployStakingPool err: ', error);
            }
        setWithdrawLoading(false);
    };
    return (
        <>
            <Container>
            {/* body */}
            <ModalBody sx={{position: 'relative'}}>
                {!isSepparatePage &&
                    <>
                        <ArrowBackContainer onClick={onClose}>
                            <ArrowBackIosNewIcon sx={{width: '20px', height: '20px'}}/>
                        </ArrowBackContainer>
                    </>
                }
                <HeaderContainer>
                    <PoolName>
                        {lockerInfo?.name} Locker
                    </PoolName>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'right',alignItems: 'right'}}>
                        <PoolName sx={{justifyContent: {xs:'center', md: 'right'}}}>
                            Created by
                        </PoolName>
                        <PoolName sx={{justifyContent: {xs:'center', md: 'right'}}}>
                            {truncate(lockerInfo?.owner, 4)}
                            <IconButton color={'primary'} onClick={(e) => {
                                e.stopPropagation();
                                goToChainExplorer(Number(selectedChainId), lockerInfo?.owner);
                            }}>
                                <OpenInNewIcon color={'primary'}/>
                            </IconButton>
                        </PoolName>
                    </Box>
                </HeaderContainer>
            </ModalBody>

            <Grid container display='flex' spacing={2} justifyContent='center'>
                {tableFields.length > 0 && tableFields.map((field:any)=> (
                    <Grid item xs={12} md={6}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{
                            background: "rgba(6,1,1,0.3)",
                            minHeight: '42px',
                            padding: '0px 5px',
                            borderRadius: '10px',
                            minWidth: '200px'
                        }}>
                            <InfoLabelBegin>
                                {field?.name}
                            </InfoLabelBegin>
                            <Box sx={{display:'flex', justifyContent: 'right',alignItems: 'center', width: '100%',}}>
                                <InfoLabelEnd sx={{maxWidth: '100px'}}>
                                    {field?.value}
                                </InfoLabelEnd>
                                {field?.icon}
                            </Box>
                        </Box>
                    </Grid>

                ))}
            </Grid>
            
            <Grid container display='flex' spacing={2} mt={2} mb={2} justifyContent='center' sx={{border: '0px solid red'}}>
                <Grid item xs={12} md={6}>
                    <Box display='flex' flexDirection='column' gap={2} sx={{border: '0px solid red'}}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{
                            background: "rgba(6,1,1,0.3)",
                            padding: '5px',
                            minHeight: '42px',
                            borderRadius: '10px',
                            minWidth: '200px',
                            border: lockerInfo?.poolEndTime * 1000 - Date.now() > 0 ? '2px solid green' : '2px solid red'
                        }}>
                            <InfoLabelBegin sx={{maxWidth: '150px'}}>
                               {lockerInfo?.poolEndTime * 1000 - Date.now() > 0 ? ' Lock Expires in':'Lock Expired'}
                            </InfoLabelBegin>
                            {lockerInfo?.poolEndTime * 1000 - Date.now() > 0 ? (
                                <ExtendedTimer days={days} hours={hours} minutes={minutes} seconds={seconds}
                                               showSymbols={true}/>

                            ) : <ContainedButton onClick={onWithdraw} sx={{display: account?.toLowerCase() === lockerInfo?.owner.toLowerCase() ? 'flex':'none',maxWidth: '100px', height: '28px'}}>
                                {isWithdrawLoading ?
                                    <CircularProgress size={20}
                                                      sx={{color: 'white'}}/> : ('Withdraw')}
                            </ContainedButton>}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <RelockSection>
                            <ContainedTextField
                                variant={'outlined'}
                                placeholder="Extend lock for days"
                                fullWidth
                                label = 'Extend lock for days'
                                value={relockDaysCount}
                                onChange={onChangeRelockDaysCount}
                                autoComplete = 'off'
                                disabled={account?.toLowerCase() !== lockerInfo?.owner.toLowerCase()}
                                InputLabelProps={{
                                    sx: {
                                        top: '50%',
                                        left: '35%',
                                        transform: 'translate(-50%, -50%)',
                                        textAlign: 'center',
                                        transition: 'all 0.4s ease',
                                    },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "transparent",
                                        }
                                    },
                                    '& .MuiInputBase-root': {
                                        height: '45px',
                                        backgroundColor: 'rgba(6,1,1,0.3)',
                                        textAlign: 'center',
                                    },
                                    '& .MuiInputLabel-shrink': {
                                        top: 0,
                                        left: 0,
                                        transform: 'translate(14px, -50%) scale(0.75)',
                                        textAlign: 'left',
                                        width: 'auto',
                                    },
                                    '& input::placeholder': {
                                        textAlign: 'left',
                                    },
                                }}
                            />

                            <ContainedButton onClick={onRelock}
                                             disabled={account?.toLowerCase() !== lockerInfo?.owner.toLowerCase()}
                                             sx={{maxWidth: '150px', height: '45px'}}>
                                {isRelockLoading ?
                                    <CircularProgress size={20}
                                                      sx={{color: 'white'}}/> : ('ReLOCK')}
                            </ContainedButton>
                             
                           
                        </RelockSection>
                    </Box>
                </Grid>
            </Grid>
            
            <InfoSection/>
        </Container>
        </>
    );
};

export default LockerModal;
