import { useCallback, useEffect, useState } from 'react';
import { getTokensInfo } from 'utils/backend';
import {  getTokensDexInfo } from 'config/constants/backend';

export const useFtmPrice = (chainId: number) => {
  const [balance, setBalance] = useState<number>(0);
  
  const fetchBalance = useCallback(async () => {
    const offchainData = await getTokensInfo({});
    try{
       if( chainId === 250 || chainId === 146 ){
        if(!offchainData)
          return
          for( let i = 0; i < offchainData.length; i+=1 ){
              if( offchainData[i].tokenAddress === "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83" ){
                  setBalance(offchainData[i].priceInUSD)
                  break;
              }
          }
       }
       else if( chainId === 8453 ){

        const offchainData = await getTokensDexInfo({});
        if(!offchainData)
          return
        for( let i = 0; i < offchainData.length; i+=1 ){
            if( offchainData[i].pairAddress === "0xb2cc224c1c9fee385f8ad6a55b4d94e92359dc59" ){
                  setBalance(offchainData[i].priceUSD)
                  return
            }
          }
        setBalance(0)
       }

    }
    catch(err){
      console.log(err)
    }
  }, [chainId]);

  useEffect(() => {
    fetchBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBalance();

    setTimeout(() => {
      fetchBalance();
    }, 30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return balance;
};
