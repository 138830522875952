import {CHAIN_INFO, SUPPORTED_CHAINS} from 'config/constants/chains';

export const getChainTokenSymbol = (chainId?: number) => {
  if (!chainId) return '';
  const chain = SUPPORTED_CHAINS.find((row: any) => row.mainnet === chainId || row.testnet === chainId);
  if (!chain) return '';

  return chain.nativeCurrency;
};

function checkProtocolAndOpen(url: any) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    const httpsUrl = 'https://' + url;
    window.open(httpsUrl);
  } else {
    window.open(url);
  }
}

export function goToChainExplorer(selectedChainId: number, address: string){
  checkProtocolAndOpen(CHAIN_INFO[selectedChainId].explorer + 'address/' + address)
}