import { FC, useEffect, useState } from 'react';
import { Box, Grid, Pagination, styled, } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useWeb3React } from '@web3-react/core';
import { ceil } from 'lodash';
import { useFtmPrice } from 'hooks/useFTMPrice';
import StakingMini from "./StakingMini";
import StakingModal from "./StakingModal";
import {useStakingLauncherInfo} from "../../hooks/useStakingLauncherInfo";
import {StakingPoolInfo} from "../../types/stakingPool";
import {
    fetchDeployedStakingDataAsync,
    fetchDeployedStakingSizeAsync,
} from "../../state/stakingLauncher/stakingSlice";
import { usePinata } from 'hooks/usePinata';
import FilterBox from "../common/FilterBox/FilterBox";

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    maxWidth: '1900px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '200px',
    minWidth: '300px',
    [theme.breakpoints.up('xs')]: {
        padding: '0px 16px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '20px 32px',
    },
}));


interface PoolCardProps {
    isStaking: boolean
    handleOpenStakingMini: (state: boolean) => void;
}

const ListedStaking: FC<PoolCardProps> = ({ isStaking, handleOpenStakingMini }) => {
    const { selectedChainId } = useAppSelector((state) => state.chain);
    const { deployedStakers, size, stakerReloadCount } = useAppSelector((staking) => staking.staking);
    const [reloadData, setReloadData] = useState(false);
    const [reloadCounter, setReloadCounter] = useState(0);
    const {combinedData} = usePinata(reloadCounter)
    const { account } = useWeb3React();

    const [deployedTokensLen, setDeployedLen] = useState(0);
    const [tokensPerPage, setTokensPerPage] = useState(20);
    const [pages, setPages] = useState(0);
    const [loadedTokens, setLoadedTokens] = useState([])
    const [page, setPage] = useState(1);
    const [tokens, setTokens] = useState<any>([]);
    const dispatch = useAppDispatch();

    const [isStakerOpened, setStakerOpen] = useState<boolean>();
    const [stakerInfo, setStakerInfo] = useState<any>();
    const [stakerIndex, setStakerIndex] = useState<number>();

    const [selectedStakingMini, setSelectedStakingMini] = useState<StakingPoolInfo | null>(null);
    const { infoList} = useStakingLauncherInfo();
    const ftmPrice = useFtmPrice(Number(selectedChainId));
    const [tokenReloads, setTokenReloads] = useState<number>(0);

    const [isAprChecked, setIsAprChecked] = useState(false);
    const [isActiveChecked, setActiveChecked] = useState(false);
    const [isNewestChecked, setIsNewestChecked] = useState(false);
    const [isUpdateInfo, setIsUpdateInfo] = useState<boolean>(false);

    function createIndexArray(size: number): number[] {
        return Array.from({ length: size }, (_, index) => index);
    }

    const handleAprChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAprChecked(event.target.checked);
    };

    const handleNewestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsNewestChecked(event.target.checked);
    };

    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActiveChecked(event.target.checked);
    }

    useEffect(
        () => {
            const timer1 = setTimeout(() => {

                if( size != deployedTokensLen  ){
                    setDeployedLen(size)
                    setReloadData(true)
                }

                if( reloadData ){
                    setReloadCounter(reloadCounter+1)
                    setReloadData(false);
                }
            }, 25 * 1000);
            return () => {
                clearTimeout(timer1);
            };
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deployedStakers, size, stakerReloadCount]
    );

    useEffect(
        () => {
          if( tokenReloads != stakerReloadCount ){
            setTokenReloads(stakerReloadCount)
            setReloadCounter(reloadCounter+1)
            setReloadData(false);
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deployedStakers, size, stakerReloadCount]
      );

    useEffect(() => {
        dispatch(fetchDeployedStakingSizeAsync(Number(selectedChainId)))
        // dispatch(fetchDeployedStakingDataAsync(250, createIndexArray(size)))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, size, stakerReloadCount]);



    const openStaker = ( info: any) => {
        setStakerInfo(infoList)
        setStakerOpen(true)
    };

    const closeStaker = () => {
        setStakerOpen(false)
        handleOpenStakingMini(false);
    };

    useEffect(
        () => {
            if( !isStaking ){
                setStakerOpen(false)
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isStaking]
    );

    useEffect(
        () => {
          if( ftmPrice === 0 )
            return
          if( size > 0 ){

            const tokens = []
            const indexArray = createIndexArray(size)
            const lastTokens: [number] = [0]
            for( let i = size; i > 0; i-= 1 ){
              tokens.push({index: i - 1})
              if( i < size - tokensPerPage ){
                lastTokens.push(i - 1)
              }

            }
            setTokens(tokens)


            dispatch(fetchDeployedStakingDataAsync(Number(selectedChainId), account ? account : "", indexArray , ftmPrice))
          }
          else{
            setTokens([])

          }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [size, ftmPrice, selectedChainId]
      );

      useEffect(
        () => {
          if( tokens ){
            setPages(ceil(tokens.length / tokensPerPage))
            setLoadedTokens(tokens.slice(0,tokensPerPage))
            setPage(1)
          }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tokens,]
      );

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        try{
            setLoadedTokens(tokens.slice((value-1) * tokensPerPage, (value ) * tokensPerPage))
            setPage(value);
            setIsUpdateInfo(!isUpdateInfo)
        }
        catch(err){
            console.log(err)
        }
    };

    const handleSelectStakingMini = (item: StakingPoolInfo) => {
            setStakerOpen(true)
            setSelectedStakingMini(item);
            handleOpenStakingMini(true);
    };

    const filterBoxInfo = [
        { label: 'APR', isChecked: isAprChecked, handleChange: handleAprChange},
        { label: 'Active',  isChecked: isActiveChecked , handleChange: handleActiveChange},
        { label: 'Newest',  isChecked: isNewestChecked, handleChange: handleNewestChange},
    ];

    const sortByAPRDescending = (arr: any[]) => {
        return arr.sort((a, b) => {
            const valueA = a.apr;
            const valueB = b.apr;
            return valueB - valueA;
        });
    };
    const sortByTimeDescending = (arr: any[]) => {
        return arr.sort((a, b) => {
            const valueA = a.timestamp;
            const valueB = b.timestamp;
            return valueB - valueA;
        });
    };

    const filterByActivity = (arr: any[]) => {
        return arr.filter((item) => {
            const currentDate = Math.floor((new Date()).getTime() / 1000)
            return currentDate <= item.poolEndTime;
        });
    };

    useEffect(() => {
            const tokensArray = Object.values(deployedStakers);

            if ( tokensArray.length !== 0) {
                let filteredTokens = tokensArray;
                let ret: any = [];

                if (isActiveChecked ) {
                    filteredTokens = filterByActivity(filteredTokens);
                }

                if (isAprChecked) {
                    filteredTokens = sortByAPRDescending(filteredTokens);
                }

                if (isNewestChecked) {

                    filteredTokens = sortByTimeDescending(filteredTokens);
                }

                if (!isAprChecked && !isNewestChecked && !isActiveChecked) {
                    filteredTokens = sortByTimeDescending(filteredTokens);
                }

                for(let i= 0; i < filteredTokens.length; i++)
                {
                    ret.push({index: filteredTokens[i].index})
                }
                setTokens(ret);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ isActiveChecked, isAprChecked, isNewestChecked, deployedStakers]
    );


    return (
        <>
            {isStakerOpened &&
                <StakingModal isSepparatePage={false} index={stakerIndex} onClose={closeStaker} stakerInfo={selectedStakingMini} images={combinedData}/>
            }

            { isStaking && !isStakerOpened &&
                <Container>

                    <FilterBox filterBoxInfo={filterBoxInfo}/>

                    <Grid mt={2} container spacing={4} justifyContent='center'>
                        <>
                            {loadedTokens.map((item) => (
                                <StakingMini key={item} info={item} images={combinedData} onSelect={handleSelectStakingMini} isUpdateInfo={isUpdateInfo}/>
                            ))}
                        </>

                    </Grid>
                    <Pagination page={page} onChange={handlePageChange} sx={{marginTop: '40px'}} count={pages} color="primary" />
                </Container>
            }
        </>
    );
};

export default ListedStaking;
