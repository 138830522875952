import {getDeployerStABI, getStakingLauncherInfo } from "utils/stakingLauncher";
import multicall from 'utils/multicall';
import {getLockersInfo, getTokensInfo} from "config/constants/backend";
import { ethers } from "ethers";
import { getDeployLockerAddress } from "utils/lockerLauncher";

export const fetchDeployedCount = async (chainId: number): Promise<any> => {
    try {
        const offchainDataDeployed = await getLockersInfo({chainId: chainId});
        const size = offchainDataDeployed.length;
        console.log(size)
        // TODO Parse data from DB ( blocked or not tokens )
        // 
        return {
          data: size,
        };

    } catch (error: any) {
        console.error(error);
        return 0
    }
};

export const fetchDeployedLockers = async (chainId: number, account: string, indexes: number[], nativePrice: number): Promise<any> => {

    const offchainDataDeployed = await getLockersInfo({chainId: chainId});
    const tokensPrices = await getTokensInfo({});
    const size = offchainDataDeployed.length;
    // TODO Parse data from DB ( blocked or not tokens )
    // 
    const tokensDeployed = await Promise.all(
        indexes.map(async (row: any) => {
            try{
                const lockerData = offchainDataDeployed[row]
                if(!lockerData)
                    return { index: -1 }

                let isPair = true
                let isPriceUsdc = false
                let nativePrice = 0;
                for(let x = 0; x<  tokensPrices.length; x+=1){
                    if( tokensPrices[x].tokenAddress === "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83".toLowerCase() || tokensPrices[x].tokenAddress === "0x039e2fB66102314Ce7b64Ce5Ce3E5183bc94aD38".toLowerCase())
                        nativePrice = tokensPrices[x].priceInUSD
                }
                console.log(`Native = ${nativePrice}`)
                let name = "--.--"
                let token0Name = lockerData.token0Name
                if( lockerData.token0Name === 'WFTM')
                    token0Name = "FTM"

                let token1Name = lockerData.token1Name
                if( lockerData.token1Name === 'WFTM')
                    token1Name = "FTM"

                if( lockerData.token0Name === 'wS')
                    token0Name = "SONIC"
                if( lockerData.token1Name === 'wS')
                    token1Name = "SONIC"

                if ( token0Name === "FTM" ){
                    name = token1Name + "/"+ token0Name
                } else {
                    name = token0Name + "/"+ token1Name
                }

                if ( token0Name === "SONIC" ){
                    name = token1Name + "/"+ token0Name
                } else {
                    name = token0Name + "/"+ token1Name
                }
                let tvl = Number(ethers.utils.formatEther(lockerData.amount))
                let tvlStr = ""

                if( token0Name === 'FTM' || token0Name === 'SONIC'){
                    tvl = Number(ethers.utils.formatEther(lockerData.amount)) * Number(lockerData.lpPrice0) * nativePrice
                    console.log(lockerData.lpPrice0)
                    tvlStr = "$"+ tvl
                    isPriceUsdc = true
                }

                if( token1Name === 'FTM' || token1Name === 'SONIC' ){
                    tvl = Number(ethers.utils.formatEther(lockerData.amount)) * Number(lockerData.lpPrice1) * nativePrice
                    tvlStr = "$"+ tvl
                    isPriceUsdc = true
                }
                const poolEndTime = lockerData.unlock_date

                let percentOfSupply = Number(ethers.utils.formatEther(lockerData.amount)) * 100 / lockerData.lpSupply

                if( token1Name.length === 0 ){
                    isPair = false
                    name = token0Name
                    tvl = Number(ethers.utils.formatEther(lockerData.amount))
                    percentOfSupply = Number(ethers.utils.formatEther(lockerData.amount)) * 100 / lockerData.lpSupply
                }
                // amount - ?
                // total - 100%

                return{
                    ...lockerData,
                    index: row,
                    name,
                    owner: lockerData.owner,
                    tvl,
                    poolEndTime,
                    lpAddress: lockerData.token_address,
                    token0Address: lockerData.token0Address,
                    token1Address: lockerData.token1Address,
                    lockerIndex: lockerData.lock_id,
                    percent: percentOfSupply,
                    isPair,
                    isPriceUsdc,
                    lockerAddress: getDeployLockerAddress(chainId)
                }
            }
            catch(err){
                console.log(err)
                return { index: -1 }
            }
        })
    )
    return { tokens: tokensDeployed };
}

export const fetchDeployedLockersInfo = async (chainId: number, account: string, indexes: number[], ftmPrice: number): Promise<any> => {
    const data = await fetchDeployedLockers(chainId, account, indexes, ftmPrice);
    return {
      data: data,
    };
};