import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, TextField, Typography, styled } from '@mui/material';

 
  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '400',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));
interface PoolCardProps {
}

const InfoSection: FC<PoolCardProps> = ({  }) => {
    
  return (
    <>
        <Box display='flex' flexDirection='column' justifyContent='center' sx={{border: '0px solid blue'}}>
            <Box mt={1} display='flex' flexDirection='column' sx={{
                    border: '1px solid #1B1E29',
                    backgroundColor: 'rgba(0.6, 0.6, 0.1,0.3)',
                    padding: '10px',
                    width: '100%',
                    borderRadius: '10px',
                    height: '100%',
                alignItems: 'center',
                    }}>
                  <ValueTitle>
                  IMPORTANT INFO FOR Locker CREATORS
                 </ValueTitle>
                <ValueTitle mt={1}>
Locker is immutable and cannot be changed after it's deployment.
                </ValueTitle>
                <ValueTitle>
           Locked Liquidity cannot be unlocked prematurely under any circumstance.
                </ValueTitle>
                 <ValueTitle>
                Fees: 1% from Locked Funds will be sent to Based Labs Treasury.
                 </ValueTitle>
            </Box>
        </Box>
    </>
  );
};

export default InfoSection;
