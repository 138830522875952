import {getDeployerStABI, getStakingLauncherInfo } from "utils/stakingLauncher";
import multicall from 'utils/multicall';
import { getStakersInfo } from "config/constants/backend";

export const fetchDeployedCount = async (chainId: number): Promise<any> => {
    try {
        const offchainDataDeployed = await getStakersInfo({chain_id: chainId});
        const size = offchainDataDeployed.length;
        // TODO Parse data from DB ( blocked or not tokens )
        // 
        return {
          data: size,
        };

    } catch (error: any) {
        console.error(error);
        return 0
    }
};

export const fetchDeployedStakers = async (chainId: number, account: string, indexes: number[], nativePrice: number): Promise<any> => {

    const offchainDataDeployed = await getStakersInfo({chain_id: chainId});
    const size = offchainDataDeployed.length;
    // TODO Parse data from DB ( blocked or not tokens )
    // 

    const tokensDeployed = await Promise.all(
        indexes.map(async (row: any) => {
            try{
                const stakerData = offchainDataDeployed[row]
                if(!stakerData)
                    return { index: -1 }

                const durationHours = Number(stakerData.poolEndTime) - Number(stakerData.poolStartTime)
                const durationDays = durationHours/86400

                let totalStakedFake = 0
                if( stakerData.totalStaked === 0 )
                    totalStakedFake = 0
                else
                    totalStakedFake = stakerData.totalStaked 

                const totalStakedFtm = totalStakedFake * stakerData.stakeTokenPriceNative
                const totalRewardsFtm = stakerData.totalRewards * stakerData.rewardTokenPriceNative
                let apr = 0
                if( totalRewardsFtm > 0){
                    apr = ( totalRewardsFtm / totalStakedFtm ) * (365 / durationDays) * 100
                }


                const stakeTokenPrice = stakerData.stakeTokenAddress === '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83' ? 1 : stakerData.stakeTokenPriceNative
                const rewardTokenPrice = stakerData.rewardTokenPriceNative 
                const tvl = totalStakedFake * stakerData.stakeTokenPriceNative
                
                
                const totalStaked = totalStakedFake
                return{
                    ...stakerData,
                    index: row,
                    apr,
                    stakeTokenPrice,
                    rewardTokenPrice,
                    tvl,
                    totalStaked,
                    nativeSymbol: chainId === 250 ? "FTM" : 'SONIC',
                    subPool: [{
                        subPoolId: 0,
                        ...stakerData,
                        stakingTokenPrice: "100",
                        rewardTokenPrice: "10",
                        nativeSymbol:  chainId === 250 ?  "FTM" : 'SONIC',
                        apr: apr,
                        tvl: tvl,
                        stakingTokenCount: "0",
                        depositFee: stakerData.depositFee.toString() + "%",
                        rewardTokenCount: "0",
                    }],
                }
            }
            catch(err){
                console.log(err)
                return { index: -1 }
            }
        })
    )
    return { tokens: tokensDeployed };
}

export const fetchDeployedStakersInfo = async (chainId: number, account: string, indexes: number[], ftmPrice: number): Promise<any> => {
    const data = await fetchDeployedStakers(chainId, account, indexes, ftmPrice);
    return {
      data: data,
    };
};