import React, {useCallback} from 'react';
import {Box, CircularProgress} from '@mui/material';
import useSearchComponent from "../../../hooks/useSearchComponent";
import {ContainedTextField} from "../Input/ContainedTextField";
import {ContainedButton} from "../Button";


interface SearchBoxProps<T> {
    items: T[];
    searchField: keyof T;
    onSearchResults: (results: T[]) => void;
}

const SearchComponent = <T extends object>({ items, searchField, onSearchResults }: SearchBoxProps<T>) => {
    const { searchQuery, setSearchQuery, handleSearch, searchResults, loading } = useSearchComponent<T>(items, searchField);

    const handleClick = useCallback(async () => {
        console.log("searchQuery", searchQuery);
        const results = await handleSearch(searchQuery);
        onSearchResults(results);
        console.log('SearchComponent searchResults', results);
    }, [handleSearch, onSearchResults, searchQuery]);

    const handleKeyDown = useCallback(async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await handleClick();
        }
    }, [handleClick]);

    return (
        <Box sx={{display: 'flex', width: '100%', gap: '20px'}}>
            <ContainedTextField
                variant={'outlined'}
                placeholder="Enter Liquidity Pair Address here"
                fullWidth
                label = 'Enter Liquidity Pair Address here'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                autoComplete = 'off'
                InputLabelProps={{
                    sx: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        textAlign: 'center',
                        transition: 'all 0.3s ease',
                    },
                }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "transparent",
                        }
                    },
                    '& .MuiInputBase-root': {
                        height: '50px',
                        backgroundColor: 'rgba(6,1,1,0.3)',
                        textAlign: 'center',
                    },
                    '& .MuiInputLabel-shrink': {
                        top: 0,
                        left: 0,
                        transform: 'translate(14px, -50%) scale(0.75)',
                        textAlign: 'left',
                        width: 'auto',
                    },
                    '& input::placeholder': {
                        textAlign: 'center',
                    },
                }}
            />
            <ContainedButton
                variant="outlined"
                color="primary"
                sx={{height: '50px', width: '100px'}}
                onClick={handleClick}
            >
                {loading ? <CircularProgress size={24} /> : "Search"}
            </ContainedButton>
        </Box>
    );
};

export default SearchComponent;