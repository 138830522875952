import {FC} from 'react';
import {Box, Typography, styled} from '@mui/material';


const ValueTitle = styled(Typography)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '400',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
        img: {
            display: 'none',
        },
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '14px',
        lineHeight: '18px',
        img: {
            display: 'block',
        },
    },
}));

interface PoolCardProps {
}

const InfoSection: FC<PoolCardProps> = ({}) => {

    return (
        <>
            <Box display='flex' flexDirection='column' justifyContent='center' sx={{border: '0px solid blue'}}>
                <Box mt={1} display='flex' flexDirection='column' sx={{
                    border: '1px solid #1B1E29',
                    backgroundColor: 'rgba(0.6, 0.6, 0.1,0.3)',
                    padding: '10px',
                    width: '100%',
                    borderRadius: '10px',
                    height: '100%',
                    alignItems: 'center',
                }}>
                    <ValueTitle>
                        IMPORTANT INFO
                    </ValueTitle>
                    <ValueTitle mt={0}>
                        Lock period can be extended only by Lock Creator.
                    </ValueTitle>
                    <ValueTitle>
                        Lock expires in 30 days, you extend lock for 300 days - new expiration in 330 days.
                    </ValueTitle>
                    <ValueTitle>
                        Fees: None.
                    </ValueTitle>
                </Box>
            </Box>
        </>
    );
};

export default InfoSection;
