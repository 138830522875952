import React, {FC, useCallback, useEffect, useState} from 'react';
import { Box, Grid, Pagination, styled, } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useWeb3React } from '@web3-react/core';
import { ceil } from 'lodash';
import { useFtmPrice } from 'hooks/useFTMPrice';
import LockerMini from "./LockerMini";
import LockerModal from "./LockerModal";
import {useStakingLauncherInfo} from "../../hooks/useStakingLauncherInfo";
import {StakingPoolInfo} from "../../types/stakingPool";
import {
    fetchDeployedLockerDataAsync,
    fetchDeployedLockerSizeAsync,
} from "../../state/lockerLauncher/lockerSlice";
import { usePinata } from 'hooks/usePinata';
import FilterBox from "../common/FilterBox/FilterBox";
import SearchComponent from "../common/SearchComponent/SearchComponent";
import RelockNotificationModal from "../Modal/NotificationModal/RelockNotificationModal";
import WithdrawNotificationModal from "../Modal/NotificationModal/WitdrawNotificationModal";

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    maxWidth: '1900px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '200px',
    minWidth: '300px',
    [theme.breakpoints.up('xs')]: {
        padding: '0px 16px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '20px 32px',
    },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0px solid red',
    [theme.breakpoints.up('xs')]: {
        width: '400px',
        height: '50px'
    },
    [theme.breakpoints.up('md')]: {
        width: '800px',
        height: '50px',
    },
}));


interface ListedLockerProps {
    handleOpenLockerMini: (state: boolean) => void;
}

const ListedLocker: FC<ListedLockerProps> = ({ handleOpenLockerMini }) => {
    const { selectedChainId } = useAppSelector((state) => state.chain);
    const { deployedLockers, size, lockerReloadCount } = useAppSelector((locker) => locker.locker);
    const [reloadData, setReloadData] = useState(false);
    const [reloadCounter, setReloadCounter] = useState(0);
    const {combinedData} = usePinata(reloadCounter)
    const { account } = useWeb3React();

    const [deployedLockersLen, setDeployedLen] = useState(0);
    const [cardsPerPage, setTokensPerPage] = useState(20);
    const [pages, setPages] = useState(0);
    const [loadedLockers, setLoadedLockers] = useState([])
    const [page, setPage] = useState(1);
    const [lockers, setLockers] = useState<any>([]);
    const dispatch = useAppDispatch();

    const [isLockerOpened, setLockerOpen] = useState<boolean>(false);
    const [lockerIndex, setLockerIndex] = useState<number>();

    const [selectedLockerMini, setSelectedLockerMini] = useState<StakingPoolInfo | null>(null);
    const ftmPrice = useFtmPrice(Number(selectedChainId));
    const [tokenReloads, setTokenReloads] = useState<number>(0);

    const [isLiquidityChecked, setIsLiquidityChecked] = useState(false);
    const [isActiveChecked, setActiveChecked] = useState(false);
    const [isNewestChecked, setIsNewestChecked] = useState(false);
    const [isUpdateInfo, setIsUpdateInfo] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<any>([]);
    const [relockModalOpen, setRelockModalOpen] = useState(false);
    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);

    function createIndexArray(size: number): number[] {
        return Array.from({ length: size }, (_, index) => index);
    }

    const handleLiquidityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLiquidityChecked(event.target.checked);
    };

    const handleNewestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsNewestChecked(event.target.checked);
    };

    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActiveChecked(event.target.checked);
    }

    useEffect(
        () => {
            const timer1 = setTimeout(() => {

                if( size != deployedLockersLen  ){
                    setDeployedLen(size)
                    setReloadData(true)
                }

                if( reloadData ){
                    setReloadCounter(reloadCounter+1)
                    setReloadData(false);
                }
            }, 25 * 1000);
            return () => {
                clearTimeout(timer1);
            };
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deployedLockers, size, lockerReloadCount]
    );

    useEffect(
        () => {
          if( tokenReloads != lockerReloadCount ){
            setTokenReloads(lockerReloadCount)
            setReloadCounter(reloadCounter+1)
            setReloadData(false);
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deployedLockers, size, lockerReloadCount]
      );

    useEffect(() => {
        dispatch(fetchDeployedLockerSizeAsync(Number(selectedChainId)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, size, lockerReloadCount, selectedChainId]);

    const closeLocker = () => {
        setLockerOpen(false)
        handleOpenLockerMini(false);
    };

    useEffect(
        () => {
            // console.log(`Use effect ${ftmPrice}`)
            // if (ftmPrice === 0)
            //     return
            if (size > 0) {

                const lockers = []
                const indexArray = createIndexArray(size)
                const lastLockers: [number] = [0]
                for (let i = size; i > 0; i -= 1) {
                    lockers.push({index: i - 1})
                    if (i < size - cardsPerPage) {
                        lastLockers.push(i - 1)
                    }

                }
                setLockers(lockers)
                dispatch(fetchDeployedLockerDataAsync(Number(selectedChainId), account ? account : "", indexArray, ftmPrice))
            } else {
                setLockers([])

            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [size, ftmPrice, selectedChainId]
    );

    useEffect(
        () => {
            if (lockers) {
                setPages(ceil(lockers.length / cardsPerPage))
                setLoadedLockers(lockers.slice(0, cardsPerPage))
                setPage(1)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [lockers,]
    );

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        try{
            setLoadedLockers(lockers.slice((value-1) * cardsPerPage, (value ) * cardsPerPage))
            setPage(value);
            setIsUpdateInfo(!isUpdateInfo)
        }
        catch(err){
            console.log(err)
        }
    };

    const handleSelectLockerMini = (item: StakingPoolInfo) => {
            setLockerOpen(true)
            setSelectedLockerMini(item);
            handleOpenLockerMini(true);
    };

    const filterBoxInfo = [
        { label: 'Liquidity Value Locked', isChecked: isLiquidityChecked, handleChange: handleLiquidityChange},
        { label: 'Active',  isChecked: isActiveChecked , handleChange: handleActiveChange},
        { label: 'Newest',  isChecked: isNewestChecked, handleChange: handleNewestChange},
    ];

    const sortByLiquidityDescending = (arr: any[]) => {
        return arr.sort((a, b) => {
            const valueA = a.apr;
            const valueB = b.apr;
            return valueB - valueA;
        });
    };
    const sortByTimeDescending = (arr: any[]) => {
        return arr.sort((a, b) => {
            const valueA = a.timestamp;
            const valueB = b.timestamp;
            return valueB - valueA;
        });
    };

    const filterByActivity = (arr: any[]) => {
        return arr.filter((item) => {
            const currentDate = Math.floor((new Date()).getTime() / 1000)
            return currentDate <= item.poolEndTime;
        });
    };

    useEffect(() => {
            const tokensArray = Object.values(deployedLockers);

            if ( tokensArray.length !== 0) {
                let filteredTokens = tokensArray;
                let ret: any = [];

                if (isActiveChecked ) {
                    filteredTokens = filterByActivity(filteredTokens);
                }

                if (isLiquidityChecked) {
                    filteredTokens = sortByLiquidityDescending(filteredTokens);
                }

                if (isNewestChecked) {

                    filteredTokens = sortByTimeDescending(filteredTokens);
                }

                if (!isLiquidityChecked && !isNewestChecked && !isActiveChecked) {
                    filteredTokens = sortByTimeDescending(filteredTokens);
                }

                for(let i= 0; i < filteredTokens.length; i++)
                {
                    ret.push({index: filteredTokens[i].index})
                }
                setLockers(ret);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ isActiveChecked, isLiquidityChecked, isNewestChecked, deployedLockers]
    );
    const handleSearchResults = useCallback((results: any[]) => {
        setSearchResults(results);
        console.log(searchResults)
    }, []);
    
    const handleRelockModalOpen = ()=>{
        setRelockModalOpen(true);
        closeLocker();
    }

    const handleWithdrawModalOpen = ()=>{
        setWithdrawModalOpen(true)
        closeLocker()
    }

    return (
        <>
            {relockModalOpen && <RelockNotificationModal open={relockModalOpen} handleClose={() => {setRelockModalOpen(false);}}/>}
            {withdrawModalOpen && <WithdrawNotificationModal open={withdrawModalOpen} handleClose={() => {setWithdrawModalOpen(false);}}/>}
            {isLockerOpened &&
                <LockerModal isSepparatePage={false} index={lockerIndex} onClose={closeLocker}
                             lockerInfo={selectedLockerMini} images={combinedData}
                relockModalOpen={handleRelockModalOpen}
                withdrawModalOpen={handleWithdrawModalOpen}/>
            }

            {!isLockerOpened &&
                <Container>
                    <SearchContainer>
                        <SearchComponent searchField={"lpAddress"} items={Object.values(deployedLockers)} onSearchResults={handleSearchResults}/>
                    </SearchContainer>

                    <Grid mt={2} container spacing={4} justifyContent='center'>
                        <>
                            {searchResults.length === 0
                                ? loadedLockers.map((locker) => (
                                    <LockerMini key={locker} info={locker} images={combinedData}
                                                onSelect={handleSelectLockerMini} isUpdateInfo={isUpdateInfo} />
                                ))
                                : searchResults.map((locker: any) => (
                                    <LockerMini key={locker} info={locker} images={combinedData}
                                                onSelect={handleSelectLockerMini} isUpdateInfo={isUpdateInfo} />
                                ))
                            }
                        </>
                    </Grid>
                    <Pagination page={page} onChange={handlePageChange} sx={{marginTop: '40px'}} count={pages}
                                color="primary"/>
                </Container>
            }
        </>
    );
};

export default ListedLocker;
