import { stakingLauncherInfos } from 'config/constants/stakingLauncher';
import DeployerStAbi from 'config/abi/DeployerSt.json'

export const getStakingLauncherInfo = (chainId: number) => {
    const bridge = stakingLauncherInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
    return bridge;
};

export const getDeployerStABI = (chainId: number) => {
    if( chainId === 169 ){
        return DeployerStAbi
    }
    if( chainId === 250 || chainId === 146 ){
        return DeployerStAbi
    }
    return [];
};

