import {getDeployerStABI, getStakingLauncherInfo } from "utils/stakingLauncher";
import {useWeb3React} from "@web3-react/core";
import useContract from "./useContract";
import {AbiItem} from "web3-utils";
import {useCallback, useEffect, useState} from "react";
import {BigNumber, ethers} from "ethers";
import ERC20 from "../config/abi/Erc20.json";
import {setPendingTxHash} from "../state/modal/modalSlice";
import {useDispatch} from "react-redux";
import { getDeployerLockerABI, getDeployLockerAddress } from "utils/lockerLauncher";
import { getLockerAddress } from "utils/deployTokens";

export const useDeployLockerInfo = (chainId: number, rewardTokenAddress: string, counter: number) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getDeployerLockerABI(chainId) as unknown as AbiItem, getDeployLockerAddress(chainId));
    const tokenContract = useContract(ERC20 as unknown as AbiItem, rewardTokenAddress);

    // 0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9
    useEffect(() => {
        async function getStakeInfo() {
          if( !contract || !tokenContract  || !account ) return;
          let accountBalance = BigNumber.from(0);
          let allowance = BigNumber.from(0);
          try {
            if( contract ){
                if( account ){
                    accountBalance = await tokenContract.balanceOf(account);
                    allowance = await tokenContract.allowance(account, contract.address)
                }
                console.log(allowance)
                setStat({accountBalance, allowance});
            }
          } catch (err) {
            console.log(err)
            // setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: "", stakingToken: "", poolId: 0, isLp: false});
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, tokenContract, contract, account, counter]);
      return stat;
  }

  export const useDeployTokenInfo = (chainId: number, tokenAddress: string, counter: number) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const tokenContract = useContract(ERC20 as unknown as AbiItem, tokenAddress);
  
    useEffect(() => {
        async function getStakeInfo() {
          if(  !tokenContract ) return;
          let name = "";
          let symbol = "";
          let decimals = "";
          try {
            if( tokenContract ){
                name = await tokenContract.name()
                symbol = await tokenContract.symbol()
                decimals = await tokenContract.decimals()
                setStat({name: name, symbol: symbol, decimals: decimals, address: tokenAddress})
            }
          } catch (err) {
            console.log(err)
            setStat(undefined)
            // setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: "", stakingToken: "", poolId: 0, isLp: false});
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, tokenContract, account, counter]);
      return stat;
  }

export const useDeployerLocker = (chainId:number, rewardTokenAddress: string,) => {
    const {account} = useWeb3React();
    const contract = useContract(getDeployerLockerABI(chainId) as unknown as AbiItem, getDeployLockerAddress(chainId));
    const tokenContract = useContract(ERC20 as unknown as AbiItem, rewardTokenAddress);
    const dispatch = useDispatch();

    async function getStakerAddressAndBlockHashFromTransaction(
        // eslint-disable-next-line
        tx: any
      ): Promise<{ stakerAddress: string; blockHash: string }> {
        const receipt = await tx.wait(1);
        if (receipt.status !== 1) {
          throw new Error();
        }
        const events = receipt.events.filter((e: { event: string }) => e.event === 'OwnershipTransferred');
        return { stakerAddress: events[0].address, blockHash: receipt.blockHash };
      }

    const handleDeploy = useCallback(
        async (rewardToken: string,
               amount: string,
               runningTime: string): Promise<string | undefined> => {
            if (!account || !contract || !tokenContract || rewardToken.length === 0 || amount.length === 0  || runningTime.length === 0) throw ("err");
            try {

                const parsedAmount = ethers.utils.parseUnits(amount, 18);

                console.log(`Deploy ${amount} ${parsedAmount} ${rewardToken} ${ Number((new Date().getTime()/1000).toFixed(0)) + Number(runningTime)* 86400 + 1} ${account}`)

                const tx = await contract.createLock(
                    rewardToken,
                    parsedAmount,
                    Number((new Date().getTime()/1000).toFixed(0)) + Number(runningTime)* 86400 + 1,
                    account,
                );

                const receipt = await tx.wait(1);

                if (receipt.status !== 1) {
                    throw new Error("Transaction failed");
                }
                console.log(tx);
                return tx?.hash;
            } catch (error) {
                console.error("Error deploying staking pool:", error);
                throw new Error("Failed to deploy staking pool");
            }
        },
        [account, contract, tokenContract]
    );

    const handleRelockExtend = useCallback(
        async (lpToken: string,
               lockId: number,
               tokenIndex: number,
               runningTime: string): Promise<string | undefined> => {
            if (!account || !contract || lpToken.length === 0  || runningTime.length === 0) throw ("err");
            try {

                // const parsedAmount = ethers.utils.parseUnits(amount, 18);
                const num = await contract.getUserNumLocksForToken(account, lpToken);
                console.log(`Num = ${num}`)
                let foundindex = -1;
                try{
                    for( let i = 0; i < num; i+=1 ){
                        const ret = await contract.getUserLockForTokenAtIndex(account, lpToken, i);
                        
                        console.log(`Current index ${i} User lock Id: ${Number(ret[3])}`)
                        if( Number(ret[3]) ===  lockId ){
                            foundindex = i
                            break
                        }
                    }
                }
                catch(excc){}

                if( foundindex === -1 )
                    return

                console.log(`We need lockId : ${lockId} Found at index ${foundindex}`)
                console.log(`increaseLock ${lpToken} ${tokenIndex} ${lockId} ${Number(runningTime)* 86400 + 1}`)

                const tx = await contract.increaseLock(
                    lpToken,
                    foundindex,
                    lockId,
                    Number(runningTime)* 86400 + 1
                );
                

                const receipt = await tx.wait(1);

                if (receipt.status !== 1) {
                    throw new Error("Transaction failed");
                }

                return tx?.hash;
            } catch (error) {
                console.error("Error deploying staking pool:", error);
                throw new Error("Failed to deploy staking pool");
            }
        },
        [account, contract]
    );

    const handleWithdraw = useCallback(
        async (lpToken: string,
               lockId: number,
               tokenIndex: number): Promise<string | undefined> => {
            if (!account || !contract || lpToken.length === 0) throw ("err");
            try {

                // const parsedAmount = ethers.utils.parseUnits(amount, 18);

                console.log(`withdraw ${lpToken} ${tokenIndex} ${lockId}`)
                const num = await contract.getUserNumLocksForToken(account, lpToken);
                console.log(`Num = ${num}`)
                let foundindex = -1;
                try{
                    for( let i = 0; i < num; i+=1 ){
                        const ret = await contract.getUserLockForTokenAtIndex(account, lpToken, i);
                        
                        console.log(`Current index ${i} User lock Id: ${Number(ret[3])}`)
                        if( Number(ret[3]) ===  lockId ){
                            foundindex = i
                            break
                        }
                    }
                }
                catch(excc){}

                if( foundindex === -1 )
                    return
                
                console.log(`We need lockId : ${lockId} Found at index ${foundindex}`)
                const tx = await contract.withdraw(
                    lpToken,
                    foundindex,
                    lockId
                );
               

                const receipt = await tx.wait(1);

                if (receipt.status !== 1) {
                    throw new Error("Transaction failed");
                }

                return tx?.hash;
            } catch (error) {
                console.error("Error deploying staking pool:", error);
                throw new Error("Failed to deploy staking pool");
            }
        },
        [account, contract]
    );

    const handleApprovePurchaseToken = useCallback(async ( amount: string,): Promise<string | undefined> => {
        if (!account || !contract || !tokenContract ||  amount.length === 0 ) return '';
        const parsedAmount = ethers.utils.parseUnits(amount, 18);
        const tx = await tokenContract.approve(contract.address, parsedAmount);

        dispatch(setPendingTxHash(tx.hash));
        const receipt = await tx.wait();

        if (receipt.status !== 1) {
            throw new Error();
        }

        return tx.txHash;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, dispatch, contract, tokenContract]);

    return {
        onDeploy: handleDeploy,
        onApprove: handleApprovePurchaseToken,
        onRelockExtend: handleRelockExtend,
        onWithdrawLocker: handleWithdraw
    };
};
