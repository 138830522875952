import { createSlice } from '@reduxjs/toolkit';
import {
    fetchDeployedCount,
    fetchDeployedLockersInfo
} from './fetchLockerLauncherGlobalInfo';


interface LockerContractState {
    deployedLockers: { [index: number]: {isUserToken: boolean, symbol: string, deployer: string, lpAddress: string, rewardTokenAddress: string, index: number} };
    size: number;
    lockerReloadCount: 0

}


const initialState: LockerContractState = {
    deployedLockers: [],
    size: 0,
    lockerReloadCount: 0
};

export const lockerContractSlice = createSlice({
    name: 'locker',
    initialState,
    reducers: {
        setDeployedLockerSize: (state, action) => {
            state.size = action.payload.data;
        },
        setFarmGlobalData: (state, action) => {
            state.deployedLockers = [];
            for (let i = 0; i < action.payload.data.tokens.length; i += 1) {
                if (action.payload.data.tokens[i].index !== -1)
                    state.deployedLockers[action.payload.data.tokens[i].index] = action.payload.data.tokens[i];
            }
        },
        setLockerReloadCount: (state, action) => {
            state.lockerReloadCount = action.payload.data;
        },
    },
});

export const { setDeployedLockerSize, setFarmGlobalData, setLockerReloadCount } = lockerContractSlice.actions;

export const setLockersReloadCountAsync = (count: number) => async (dispatch: any): Promise<void> => { 
    dispatch( setLockerReloadCount( {
        data: count
    }))
  }
  
export const fetchDeployedLockerSizeAsync =
    (chainId: number) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchDeployedCount(chainId);
            // console.log(`Fetch locker size ${chainId}`)
            dispatch(
                setDeployedLockerSize({
                    data,
                })
            );
        };

export const fetchDeployedLockerDataAsync =
    (chainId: number, account: string, indexes: number[], ftmPrice: number) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchDeployedLockersInfo(chainId, account, indexes, ftmPrice)

            dispatch(
                setFarmGlobalData({
                    data,
                })
            );
        };

export default lockerContractSlice.reducer;
