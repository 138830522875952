import {FC, useState} from 'react';
import {Box, styled, CircularProgress} from '@mui/material';
import {ImageType} from 'react-images-uploading';
import {ContainedButton} from "../../common/Button";


const Container = styled(Box)(({theme}) => ({
    border: '0px solid red',
    gap: '16px',
    display: 'flex',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
}));

interface Props {
    isCheckErrors: boolean;
    isApproved: boolean;
    isFieldEmpty: boolean;
    onCreateClick: () => void;
    isLoading: boolean;
    isDeployed: boolean;
}


const LockerAssetsSection: FC<Props> = ({
                                            isFieldEmpty,
                                            onCreateClick,
                                            isApproved,
                                            isLoading,
                                            isDeployed
                                        }) => {

    return (
        <>
            <Box mt={2} gap={2} display='flex' justifyContent='center' alignItems='center'
                 sx={{
                     border: '0px solid blue', flexDirection: 'column',
                     padding: {xs: '0px', md: '0px 30px 0px 30px'}
                 }}>

                <Container>
                    <Box sx={{border: '0px solid red', width: '100%', minHeight: '100%', flex: 1}}>
                        <ContainedButton
                            sx={{
                                flex: 1,
                                fontSize: '25px',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                textAlign: 'center',
                                lineHeight: '1.2',
                            }}
                            disabled={isLoading || isDeployed || isFieldEmpty}
                            onClick={onCreateClick}>
                            {isLoading ? 
                                <CircularProgress size={20} 
                                                  sx={{color: 'white'}}/> : (isApproved ? 'Create Lock' : 'Approve Lock')}
                        </ContainedButton>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default LockerAssetsSection;
