import { FC,  useState } from 'react';
import { Box,  Typography, styled } from '@mui/material';
import {ContainedTextField} from "../../common/Input/ContainedTextField";
	
const PoolName2 = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
        textAlign: 'left'
    },
}));


interface Props {
    isCheckErrors: boolean
    rewardTokenInfo?: any,
    stakingTokenInfo?: any
    rewardTokenBalance?: string,
    lockerSectionChanges: (
                           rewardTokenAddress: string,
                           rewardTokenAmount: string,
                           stakingTokenAddress: string,) => void,
}

const LockerSection: FC<Props> = ({ lockerSectionChanges, isCheckErrors , rewardTokenInfo, stakingTokenInfo, rewardTokenBalance = ""}) => {
    const [lockerName, setLockerName] = useState<string>('');
    const [liquidityPairAddress, setLiquidityPairAddress] = useState<string>('');
    const [lpAmount, setLpAmount] = useState<string>('');
    const [lockDuration, setLockDuration] = useState<string>('');
    const [stakingPoolDuration, setStakingPoolDuration] = useState<string>('');

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }
    const onChangeLiquidityPairAddress = (e: any) => {
        const newValue = e.target.value;
        console.log(newValue)
        setLiquidityPairAddress(newValue);
        lockerSectionChanges(newValue, lpAmount, lockDuration)
    };
    
    const onChangeLpAmount = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        if( rewardTokenBalance.length > 0 ){
            if( Number(rewardTokenBalance) < e.target.value)
                return
        }
        const newValue = e.target.value;
        setLpAmount(newValue);
        lockerSectionChanges(liquidityPairAddress, newValue, lockDuration)
    };
    
    const onChangeLockDuration = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newValue = e.target.value;
        setLockDuration(newValue);
        lockerSectionChanges(liquidityPairAddress, lpAmount, newValue)

    };

  return (
    <>
         <Box display='flex' gap = {4} sx={{flexDirection: {xs: 'column', md: 'column'}, padding: {xs: '0px', md: '0px 30px 0px 30px'}, width: '100%'}} justifyContent='center'>
            <Box display='flex' gap = {2} flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue', width: '100%'}}>
                <Box gap={'5px'} display='flex' alignItems={'center'} flexDirection={'column'} sx={{
                        border: '0px solid grey',
                        width: '100%',
                        }}>
                    <ContainedTextField
                        variant={'outlined'}
                        placeholder="Enter Liquidity Pair Address here"
                        fullWidth
                        label = 'Liquidity Pair Address'
                        onChange={onChangeLiquidityPairAddress}
                        autoComplete = 'off'
                        error={isCheckErrors ? liquidityPairAddress.length === 0 : false}
                        InputLabelProps={{
                            sx: {
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                textAlign: 'center',
                                transition: 'all 0.3s ease',
                            },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '30px',
                                textAlign: 'center',
                            },
                            '& .MuiInputLabel-shrink': {
                                top: 0,
                                left: 0,
                                transform: 'translate(14px, -50%) scale(0.75)',
                                textAlign: 'left',
                                width: 'auto',
                            },
                            '& input::placeholder': {
                                textAlign: 'center',
                            },
                        }}
                    />
                    {rewardTokenInfo  && rewardTokenInfo?.address === liquidityPairAddress && liquidityPairAddress.length > 0 && 
                        <PoolName2 mt={2}>
                        {rewardTokenInfo?.name}
                        </PoolName2>
                    }
                    { rewardTokenInfo  && rewardTokenInfo?.address === liquidityPairAddress && liquidityPairAddress?.length > 0 && rewardTokenBalance.length > 0 &&
                        <PoolName2>
                        Your Balance: {rewardTokenBalance}
                        </PoolName2>
                    }   


                </Box>
                <Box display='flex' sx={{
                        border: '0px solid grey',
                        width: '100%'
                        }}>
                    <ContainedTextField
                        variant="outlined"
                        placeholder="Enter LP amount you want to lock"
                        fullWidth
                        label="LP amount"
                        value={lpAmount}
                        onChange={onChangeLpAmount}
                        autoComplete="off"
                        error={isCheckErrors ? lpAmount.length === 0 : false}
                        InputLabelProps={{
                            sx: {
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                textAlign: 'center',
                                transition: 'all 0.3s ease',
                            },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '30px',
                                textAlign: 'center',
                            },
                            '& .MuiInputLabel-shrink': {
                                top: 0,
                                left: 0,
                                transform: 'translate(14px, -50%) scale(0.75)',
                                textAlign: 'left',
                                width: 'auto',
                            },
                            '& input::placeholder': {
                                textAlign: 'center',
                            },
                        }}
                    />
                </Box>
                <Box display='flex' sx={{
                    border: '0px solid grey',
                    width: '100%'
                }}>
                    <ContainedTextField
                        variant="outlined"
                        placeholder="Enter Lock Duration (days)"
                        fullWidth
                        label="Lock Duration"
                        value={lockDuration}
                        onChange={onChangeLockDuration}
                        autoComplete="off"
                        error={isCheckErrors ? lockDuration.length === 0 : false}
                        InputLabelProps={{
                            sx: {
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                textAlign: 'center',
                                transition: 'all 0.3s ease',
                            },
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '30px',
                                textAlign: 'center',
                            },
                            '& .MuiInputLabel-shrink': {
                                top: 0,
                                left: 0,
                                transform: 'translate(14px, -50%) scale(0.75)',
                                textAlign: 'left',
                                width: 'auto',
                            },
                            '& input::placeholder': {
                                textAlign: 'center',
                            },
                        }}
                    />
                </Box>
            </Box>
          </Box>
    </>
  );
};

export default LockerSection;

