import DeployLockerAbi from 'config/abi/LockerDeployAbi.json'
import { lockers } from 'config/constants/locker';

export const getDeployerLockerABI = (chainId: number) => {
    return DeployLockerAbi;
};

export const getDeployLockerAddress = (chainId: number) => {
    const tokens = lockers.filter((row: any) => chainId === row.chain);
    if( tokens.length > 0 )
        return tokens[0].address
    return ""
    return "0x2da64B2f11e447671A8dDF5a55524c29B0fE49B1"
};

// const tokens = tokenDeployersInfo.filter((row: any) => chainId === row.chain);
// if( tokens.length > 0 )
//     return tokens[0].deployers
// return []