import {FC, useEffect, useState} from 'react';
import {Box, Grid, IconButton, styled, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from 'state/hooks';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import Card from '../common/Card/Card'
import {InfoLabelBegin, InfoLabelEnd} from "../common/Typography/InfoLabel";
import {useWeb3React} from "@web3-react/core";
import {useFtmPrice} from "../../hooks/useFTMPrice";
import ExtendedTimer from "../common/TimeComponent/ExtendedTimer";
import {shortenAddress} from "../../utils";
import {checkTextSelection} from "../../utils/eventHandlers";
import {goToChainExplorer} from "../../utils/chain";

const PoolName = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '26px',
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    overflow: 'clip',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));


interface PoolCardProps {
    info: any,
    images: any,
    onSelect: (info: any) => void,
    isUpdateInfo?: boolean,

}

const LockerMini: FC<PoolCardProps> = ({info, images, onSelect, isUpdateInfo}) => {
    const {deployedLockers, size} = useAppSelector((locker) => locker.locker);
    const [preinfo, setPreinfo] = useState<any>({})
    const {account} = useWeb3React();
    const {selectedChainId} = useAppSelector((state) => state.chain);
    const ftmPrice = useFtmPrice(Number(selectedChainId));
    const dispatch = useAppDispatch();

    const [isPoolsStarted, setIsPoolsStarted] = useState<boolean>(false);
    const [isPoolsEnded, setIsPoolsEnded] = useState<boolean>(false);
    const [isShowTime, setIsShowTime] = useState<boolean>(false);
    const [poolStartTime, setPoolStartTime] = useState<Number>(0);
    const [poolEndTime, setPoolEndTime] = useState<Number>(0);
    //const [days, hours, minutes, seconds] = useCountDownUp(Number(isPoolsStarted ? poolEndTime : poolStartTime) * 1000);

    useEffect(() => {
        if (preinfo) {

            setPoolStartTime(preinfo.poolStartTime)
            setPoolEndTime(preinfo.poolEndTime)
            const currentDate = Date.now()
            if (currentDate >= preinfo.poolStartTime) {

                setIsShowTime(true)
                setIsPoolsStarted(true)
            }
            if (currentDate >= preinfo.poolEndTime) {

                setIsShowTime(false)
                setIsPoolsEnded(true)
            }
        }
    }, [preinfo]);

    useEffect(
        () => {
            try {
                setPreinfo(deployedLockers[info.index])
            } catch {

            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deployedLockers, size, info]
    );


    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = Date.now();
            const difference = preinfo?.poolEndTime * 1000 - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / 1000 / 60) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setTimeLeft({
                    days,
                    hours,
                    minutes,
                    seconds,
                });
            } else {
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });
            }
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, [preinfo]);

    const {days, hours, minutes, seconds} = timeLeft;
    

    return (
        <>
            <Grid xs={12} md={6} sm={6} lg={4} xl={3} item
                  sx={{borderRadius: '10px', opacity: preinfo?.preinfo ? 1.0 : 1.0}}>
                <Card onClick={() => {
                    if (checkTextSelection()) {
                        return;
                    } else {
                        onSelect(preinfo)
                    }
                }}
                      minHeight='100px' justifyContent={'center'} alignItems='center' display={'flex'}
                      flexDirection='column'
                      sx={{borderRadius: '10px', padding: '15px', position: 'relative',}}>
                    <OpenInNewIcon sx={{
                        width: '25px',
                        height: '25px',
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        fill: "#E9E9E9"
                    }}/>
                    {!preinfo &&
                        <EmptyLoad width='100%' height='100%'/>
                    }
                    <Box display='flex' flexDirection='column' justifyContent={'center'} alignItems='center' gap='10px'
                         sx={{height: '100%', width: '100%', border: '0px solid yellow'}}>
                        <Box display='flex' flexDirection='row' alignItems='center' gap='10px' sx={{
                            flexDirection: {xs: 'column', md: 'row'}, height: '100%', width: '100%', minHeight: '160px',
                            border: '0px solid green'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                gap: "10px",
                                width: '100%',
                                height: '100%',
                                minHeight: '160px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '0px solid red'
                            }}>

                                <Box sx={{
                                    display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center',
                                    alignItems: 'center', border: '0px solid red'
                                }}>
                                    <PoolName>
                                        {preinfo?.name}
                                    </PoolName>
                                </Box>
                            </Box>
                        </Box>

                        <Box display='flex' gap={'0px'} flexDirection='column'
                             sx={{border: '0px solid blue', width: '100%', }}>

                            <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{
                                alignItems: 'center',
                                height: '30px',
                                borderRadius: '10px'
                            }}>
                                <InfoLabelBegin sx={{fontSize: '20px'}}>
                                    Created by
                                </InfoLabelBegin>
                                <Box sx={{display:'flex', justifyContent: 'center',alignItems: 'center', border: '0px solid red', }}>
                                    <InfoLabelEnd sx={{fontSize: '20px'}}>
                                        {preinfo?.owner ? shortenAddress(preinfo?.owner) : '--.--'}
                                    </InfoLabelEnd>
                                    <IconButton color={'primary'} onClick={(e)=>{
                                        e.stopPropagation();
                                        goToChainExplorer(Number(selectedChainId), preinfo?.owner);
                                    }}>
                                        <OpenInNewIcon color={'primary'}/>
                                    </IconButton>
                                  
                                </Box>
                            </Box>
                            
                            <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{
                                background: "rgba(6,1,1,0.3)",
                                alignItems: 'center',
                                padding: '5px',
                                height: '30px',
                                borderRadius: '10px',
                                mt: '16px'
                            }}>
                                <InfoLabelBegin>
                                    Tvl:
                                </InfoLabelBegin>
                                <InfoLabelEnd>
                                    {(preinfo?.isPriceUsdc ? '$' : '') + Number(preinfo?.tvl)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (!preinfo?.isPair ? ' ' : '')}
                                </InfoLabelEnd>
                            </Box>
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems={'center'}
                                 sx={{background: "rgba(6,1,1,0.3)", padding: '5px', borderRadius: '10px', mt: '16px'}}>
                                <Box justifyContent='center' alignItems='center' display='flex' gap='10px'
                                     flexDirection={'row'}>
                                    <InfoLabelBegin>
                                        Status
                                    </InfoLabelBegin>
                                    <Box sx={{
                                        minWidth: '20px',
                                        minHeight: '20px',
                                        maxHeight: "20px",
                                        background: preinfo?.poolEndTime * 1000 - Date.now() > 0 ? 'green' : "red",
                                        borderRadius: '10px'
                                    }}/>
                                </Box>
                                <Box sx={{
                                    border: '0px solid red',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    position: 'relative',
                                    minWidth: '200px',
                                    justifyContent: 'right',
                                    alignItems: 'center',
                                    paddingRight: preinfo?.poolEndTime * 1000 - Date.now() > 0 ? '10px' : '5px',
                                }}>
                                    {preinfo?.poolEndTime * 1000 - Date.now() > 0 &&
                                        <Typography sx={{
                                            textAlign: 'center',
                                            position: 'absolute',
                                            top: -2,
                                            left: 0
                                        }}>
                                            {'Expires in'}
                                        </Typography>
                                    }

                                    {preinfo?.poolEndTime * 1000 - Date.now() > 0 ? (
                                        <ExtendedTimer days={days} hours={hours} minutes={minutes} seconds={seconds}
                                                       showSymbols={false}/>

                                    ) : <div style={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignItems: 'center',
                                        paddingRight: '5px',
                                    }}> Ended</div>}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Grid>
        </>
    );
};

export default LockerMini;
