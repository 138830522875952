import { icons } from 'config/constants/assets';

export interface RouteType {
  key: string;
  text: string;
  url: string;
}

export const routes: { [key: number]: RouteType[] } = {
  250: [
    { key: 'token', text: 'Token Launcher', url: 'token' },
    { key: 'presale', text: 'Presale Launcher', url: 'presale' },
    { key: 'staker', text: 'Airdrop Farming', url: 'staker' },
    { key: 'stakingPools', text: 'Staking Launcher', url: 'stakingPools'},
    // { key: 'lockerLauncher', text: 'Locker Launcher', url: 'lockerLauncher'},
  ],
  8453: [
    { key: 'token', text: 'Token Launcher', url: 'token' },
    { key: 'staker', text: 'Airdrop Farming', url: 'staker' },
  ],
  146: [
    { key: 'lockerLauncher', text: 'Locker Launcher', url: 'lockerLauncher'},
    { key: 'stakingPools', text: 'Staking Launcher', url: 'stakingPools'},
  ],
};

export const extraMenuItems: { [key: number]: any[] } = {
  250: [
    {
      name: 'Main menu',
      icon: "",
      key: 'main menu',
      hasSubItems: true,
      subItems: [
        { name: 'Token Launcher', key: 'stake', link: '/token', color: '#CCC292', enabled: true },
        { name: 'Presale Launcher', key: 'stake', link: '/presale', color: '#CCC292', enabled: true },
        { name: 'Airdrop Farming', key: 'stake', link: '/staker', color: '#CCC292', enabled: true },
        { name: 'Staking Launcher', key: 'stake', link: '/stakingPools', color: '#CCC292', enabled: true },
        // { name: 'Locker Launcher', key: 'locker', link: '/lockerLauncher', color: '#CCC292', enabled: true },
      ],
    },
  ],
  8453: [
    {
      name: 'Main menu',
      icon: "",
      key: 'main menu',
      hasSubItems: true,
      subItems: [
        { name: 'Token Launcher', key: 'stake', link: '/token', color: '#CCC292', enabled: true },
        { name: 'Airdrop Farming', key: 'stake', link: '/staker', color: '#CCC292', enabled: true },
      ],
    },
  ],
  146:[
    {
      name: 'Main menu',
      icon: "",
      key: 'main menu',
      hasSubItems: true,
      subItems: [
        { name: 'Locker Launcher', key: 'locker', link: '/lockerLauncher', color: '#CCC292', enabled: true },
        { name: 'Staking Launcher', key: 'stake', link: '/stakingPools', color: '#CCC292', enabled: true },
      ],
    },
  ]
};
