// Atlantis
import AtlantisMainImg from 'assets/main_img.jpeg'
import GoalImg from 'assets/goal.png'
import AtlImg from 'assets/atl_logo.svg'

// wallets
import metamask from 'assets/images/wallets/metamask.svg';
import walletConnect from 'assets/images/wallets/wallet-connect.svg';
import coinbase from 'assets/images/wallets/coinbase.svg';
import other from 'assets/images/wallets/other.svg';
import dexscreener from 'assets/images/wallets/dex-screener.png';

// chains
import ethereumLogo from 'assets/images/chains/ethereum.svg';
import fantomLogo from 'assets/images/chains/fantom.svg';
import fantomLogo1 from 'assets/images/chains/fantom1.svg';
import avalancheLogo from 'assets/images/chains/avalanche.svg';
import polygonLogo from 'assets/images/chains/polygon.svg';
import polygonLogo1 from 'assets/images/chains/polygon1.svg';
import zkChainLogo from 'assets/images/chains/zksync.svg'
import baseChainLogo from 'assets/images/chains/baseChain.jpg';
import polygonLogo2 from 'assets/images/chains/polygon.jpeg';
// header
import chevronDownIcon1 from 'assets/images/icons/chevron-down-icon1.svg';
import HamburgerIcon from 'assets/images/icons/hamburger-icon.svg'
import SpookyRouter from 'assets/routers/spooky.svg'
import EqualRouter from 'assets/routers/equal.png'
import TimeIcon from 'assets/images/icons/time-icon.png'
import Based from 'assets/tokens/based3.png'
import Obol from 'assets/tokens/obol_simple.svg'
import FVM from 'assets/tokens/FVM.png'
import USDC from 'assets/tokens/usdc.png'
import Sonic from 'assets/tokens/sonic.png'
import WigoRouter from 'assets/routers/wigo.png'
import SpiritRouter from 'assets/routers/spirit.png'
import DiscordIco from 'assets/discordIco.svg'
import ETH from 'assets/tokens/eth.png'
import AERO from 'assets/tokens/aerodrome.png'
import BRETT from 'assets/tokens/brett.webp'
import SCALE from 'assets/tokens/SCALE.webp'
import DEGEN from 'assets/tokens/degen.png'
import BeethovenRouter from 'assets/routers/beethoven.png'
import sonicLogo from 'assets/images/chains/sonic.svg';

export const icons = {
  atlantisMain: AtlantisMainImg,
  atlToken: AtlImg,
  goal: GoalImg,
  chevronDown1: chevronDownIcon1,
  hamburger: HamburgerIcon,
  spookyRouter: SpookyRouter,
  equalRouter: EqualRouter,
  timeIcon: TimeIcon,
  WigoRouter,
  SpiritRouter,
  discord: DiscordIco,
  BeethovenRouter
};

export const images = {
};



export const tokens = {
  rewardFtm: fantomLogo1,
  basedLogo: Based,
  obolLogo: Obol,
  fvmLogo: FVM,
  usdcLogo: USDC,
  equalLogo: EqualRouter,
  sonicLogo: Sonic,
  ethLogo: ETH,
  aeroLogo: AERO,
  brettLogo: BRETT,
  scaleLogo: SCALE,
  degenLogo: DEGEN
};



export const wallets = {
  metamask,
  walletConnect,
  coinbase,
  other,
  dexscreener,
};

export const chains = {
  ethereum: ethereumLogo,
  fantom: fantomLogo,
  fantom1: fantomLogo1,
  avalanche: avalancheLogo,
  polygon: polygonLogo,
  polygon1: polygonLogo1,
  zkSync: zkChainLogo,
  base: baseChainLogo,
  polygon2: polygonLogo2,
  sonic: sonicLogo,
};

export const staking = {
};

