const lockersDeployers = [ 
    {
      chain: 250,
      address: "0x2da64B2f11e447671A8dDF5a55524c29B0fE49B1"
    },
    {
      chain: 146,
      address: "0x33CF7FedAa96997a23c7F476176813d08CA606c1"
    }
  ]

const lockers = [...lockersDeployers];
export { lockers};