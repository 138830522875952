import { FC, useEffect, useState } from 'react';
import { Box, Grid, } from '@mui/material';
import TokenSection from './Components/TokenSection';
import InfoSection from './Components/InfoSection';
import TokenAssetsSection from './Components/TokenAssetsSection';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { ImageType } from 'react-images-uploading';
import { useWeb3React } from '@web3-react/core';
import {useDeployerSt, useDeployStInfo, useDeployTokenInfo} from "../../hooks/useDeployerSt";
import { ethers} from 'ethers';
import useContract from "../../hooks/useContract";
import ERC20 from "../../config/abi/Erc20.json";
import {AbiItem} from "web3-utils";
import {getDeployerStABI, getStakingLauncherInfo} from "../../utils/stakingLauncher";
import { usePinata } from 'hooks/usePinata';
import { setStakersReloadCountAsync } from 'state/actions';
import { fetchDeployedStakingSizeAsync } from 'state/stakingLauncher/stakingSlice';
import NotificationModal from 'components/Modal/NotificationModal/NotificationModal';
import FancyDropDownBox from "../common/DropDownBox/FancyDropDownBox";


interface PoolCardProps {
}

const DeployStakingPool: FC<PoolCardProps> = ({  }) => {
    const dispatch = useAppDispatch();

    const [isCheckErrors, setIsCheckErrors] = useState<boolean>(false);
    const [rewardTokenAddress, setRewardTokenAddress] = useState<string>('');
    const [rewardTokenAmount, setRewardTokenAmount] = useState<string>('');
    const [stakingTokenAddress, setStakingTokenAddress] = useState<string>('');
    const [stakingPoolDuration, setStakingPoolDuration] = useState<string>('');
    const [stakingName, setStakingName] = useState<string>('');

    const { account } = useWeb3React();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoadingImage, setLoadingImage] = useState<boolean>(false);
    const [stakersReloadCount, setStakersReloadCount] = useState<number>(0);
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const { selectedChainId } = useAppSelector((state) => state.chain);
    const tokenContract = useContract(ERC20 as unknown as AbiItem, rewardTokenAddress);
    const contract = useContract(getDeployerStABI(Number(selectedChainId)) as unknown as AbiItem, getStakingLauncherInfo(Number(selectedChainId))[0]?.contractAddress);
    const {onDeploy, onApprove} = useDeployerSt(Number(selectedChainId), rewardTokenAddress);
    const [isDeployed, setIsDeployed] = useState<boolean>(false);
    const [userDeployedToken, setUserDeployedToken] = useState<any>(undefined);
    const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
    const [stakerAddress, setStakerAddress] = useState<string>("");
    const {uploadImageToPinata, deleteJsonFromPinata, uploadJsonToPinata} = usePinata(0)
    const [tg, setTg] = useState<string>('');
    const [x, setX] = useState<string>('');
    const [web, setWeb] = useState<string>('');
    const [buyToken, setBuyToken] = useState<string>('');
    const [imagesStake, setImagesStake] = useState<ImageType[]>([]);
    const [imagesReward, setImagesReward] = useState<ImageType[]>([]);
    const [counter, setCounter] = useState<number>(0);
    const deployInfo = useDeployStInfo(Number(selectedChainId), rewardTokenAddress, counter)
    const rewardTokenInfo = useDeployTokenInfo(Number(selectedChainId), rewardTokenAddress, counter)
    const stakingTokenInfo = useDeployTokenInfo(Number(selectedChainId), stakingTokenAddress, counter)

    const [accountRewardBalance, setAccountRewardBalance] = useState<string>('');

    const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>(true);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const rollUpDropDown = () =>
    {
        setIsExpanded(false);
    }

    useEffect(() => {
        if (
            !rewardTokenAddress ||
            !rewardTokenAmount ||
            !stakingTokenAddress ||
            !stakingPoolDuration ||
            !stakingName
        ) {
            setIsFieldEmpty(true);
        } else {
            setIsFieldEmpty(false);
        }
    }, [rewardTokenAddress, rewardTokenAmount, stakingTokenAddress, stakingPoolDuration, stakingName, tg, x, web, buyToken, imagesStake, isExpanded]);

    const clearFields = () => {
        setRewardTokenAddress('');
        setRewardTokenAmount('');
        setStakingTokenAddress('');
        setStakingPoolDuration('');
        setStakingName('');
        setTg('');
        setX('');
        setWeb('');
        setBuyToken('');
    };

    useEffect(() => {
        clearFields();
    }, [isExpanded]);

    const onConfirm = async () => {
        setLoading(true);
        if (isApproved) {

            try {
                const stakerAddress = await onDeploy(rewardTokenAddress, rewardTokenAmount, stakingName, stakingTokenAddress ,stakingPoolDuration);
                setIsDeployed(true)
                if( stakerAddress){
                    setStakerAddress(stakerAddress)

                }
            } catch (error) {
                console.log('DeployStakingPool err: ', error);
            }
        } else {
            try {
                await onApprove(rewardTokenAmount);
                setCounter(counter + 1)
            } catch (error) {
                console.log('Approve err: ', error);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        // const isApproved = account && deployInfo ? deployInfo.allowance.gte(rewardTokenAmount) : false
        if( account && deployInfo && rewardTokenAmount ){
            if( deployInfo.allowance.gte(ethers.utils.parseUnits(rewardTokenAmount, 18))){

                setIsApproved(true)
            } else {

                setIsApproved(false)
            }
        }
      }, [account, deployInfo, rewardTokenAmount]);

      useEffect(() => {
        // const isApproved = account && deployInfo ? deployInfo.allowance.gte(rewardTokenAmount) : false
        if( account && deployInfo && rewardTokenInfo ){

            try{
                const balance = ethers.utils.formatUnits(deployInfo.accountBalance, rewardTokenInfo.decimals)

                setAccountRewardBalance(Number(balance).toFixed(2))
            }
            catch(err){
                console.log(err)
            }
        }
      }, [account, deployInfo, rewardTokenInfo]);
    
    const sendImage = async (addr: string) => {
        setLoadingImage(true)
        try{
            await uploadJsonToPinata(stakerAddress,"", tg, x, web, buyToken)
        } catch(err){
        }

        try{
            if( imagesStake.length > 0 ){
                if( imagesStake[0].file ){
                    const url = await uploadImageToPinata(stakerAddress, imagesStake[0].file, 4);

                }
            }
        } catch(err){

        }
        setLoadingImage(false)
        setModalOpen(true)
        rollUpDropDown()
        dispatch(setStakersReloadCountAsync(stakersReloadCount + 1));
        dispatch(fetchDeployedStakingSizeAsync(Number(selectedChainId)))

    }

    useEffect(() => {

        if( stakerAddress.length > 0 ){
            sendImage(stakerAddress)
        }
      }, [isDeployed, stakerAddress]);

    const  tokenSectionChanges =  (rewardTokenAddress: string,
                                 rewardTokenAmount: string,
                                 stakingTokenAddress: string,
                                 stakingPoolDuration:string, 
                                 stakingName: string) => {

        setRewardTokenAddress(rewardTokenAddress);
        setRewardTokenAmount(rewardTokenAmount);
        setStakingTokenAddress(stakingTokenAddress);
        setStakingPoolDuration(stakingPoolDuration);
        setStakingName(stakingName)
    }

    const tokenAssetsSectionChanges = ( tokenTg: string, tokenX:string, tokenWeb: string,  imagesStake: ImageType[], imagesReward: ImageType[] , buyToken: string) => {

        setX(tokenX)
        setTg(tokenTg)
        setWeb(tokenWeb)
        setBuyToken(buyToken)
        setImagesStake(imagesStake)
        setImagesReward(imagesReward)

    }

    return (
        <>
            {modalOpen && <NotificationModal open={modalOpen} handleClose={() => {setModalOpen(false);}}/>}

        <FancyDropDownBox globalTittle={'Staking Launcher'} containerSX={{padding: { md: '20px 75px' },}} isExpanded={isExpanded}
                              setIsExpanded={setIsExpanded}>

            <Box display='flex' flexDirection='column' mt={2}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Box display='flex' flexDirection='column'>
                            <TokenSection isCheckErrors={isCheckErrors} tokenSectionChanges={tokenSectionChanges}
                                          rewardTokenInfo={rewardTokenInfo}
                                          stakingTokenInfo={stakingTokenInfo}
                                          rewardTokenBalance={accountRewardBalance}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box display='flex' flexDirection='column'>

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box display='flex' flexDirection='column'>
                            <TokenAssetsSection
                                isCheckErrors={isCheckErrors}
                                tokenAssetsSectionChanges={tokenAssetsSectionChanges}
                                onCreateClick={onConfirm}
                                isApproved={isApproved}
                                isLoading={isLoading}
                                isImageLoading={isLoadingImage}
                                isDeployed={isDeployed}
                                isFieldEmpty={isFieldEmpty}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <InfoSection/>

            </Box>
        </FancyDropDownBox>
        </>
    );
};

export default DeployStakingPool;
